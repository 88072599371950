import React, { useCallback, useMemo } from "react"
import Marquee from "react-fast-marquee"
import { Button, Heading } from "@atoms"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { HomeHeroWrapper } from "./styles/MainAnimation.styled"
import OptimizedImage from "../../utils/optimizedImage"
import { ContentfulVideo } from "../ContentfulVideo/ContentfulVideo"

const HomeHeroCTA = ({ callsToAction }) => {
  return (
    <div className="home-hero--buttons">
      {callsToAction &&
        callsToAction.map((cta, idx) => {
          if (cta?.type !== "triggers-video-modal") {
            return (
              <Button key={idx} ctaVariant={cta?.variant} ctaLink={cta?.link} {...cta}>
                {cta?.label}
              </Button>
            )
          }
        })}
    </div>
  )
}

export const MainAnimation = ({ component }) => {
  const {
    callsToAction,
    background,
    featuredImage,
    heading,
    headingSize,
    subheading,
  } = component

  const videoData = useMemo(
    () => callsToAction.filter(cta => cta?.reference && (cta?.reference?.videoFile || cta?.reference?.videoUrl)),
    [callsToAction]
  );

  const shuffledCompanies = useMemo(
    () =>
      [
        "adp",
        "attlasian",
        "bmc",
        "cornerstone",
        "emburse-chromeriver",
        "igloo",
        "jira",
        "office",
        "oracle",
        "paychex",
        "sage",
        "salesforce",
        "sap-ariba",
        "sap-concur",
        "sap-fieldglass",
        "sap",
        "scim",
        "service-now",
        "taleo",
        "ukg",
        "workday",
        "zendesk",
      ].sort(() => 0.5 - Math.random()),
    []
  );

  const renderMarquee = useCallback(
    props => (
      <div className="home-hero--marquee">
        <Marquee gradient={false} {...props}>
          {shuffledCompanies.map(name => (
            <img
              key={name}
              src={`/images/logos/${name}.webp`}
              loading="lazy"
              alt={`${name} logo`}
              width={100}
              height={70}
            />
          ))}
        </Marquee>
      </div>
    ),
    [shuffledCompanies]
  );

  return (
    <div data-comp={MainAnimation.displayName}>
      <HomeHeroWrapper className="home-hero--wrapper home-hero--main-animation-wrapper">
        <OptimizedImage
          className="background-image"
          loading="lazy"
          priority={true}
          image={featuredImage?.gatsbyImageData}
          src={featuredImage?.file?.url}
          alt=""
        />
        <div className="home-hero--copy-container">
          {heading && (
            <Heading
              background={background}
              headingSize={headingSize}
              className="home-hero--heading"
              style={{ textAlign: "center" }}
            >
              {heading}
            </Heading>
          )}
          {subheading?.raw && (
            <p className="home-hero--description">
              {documentToReactComponents(JSON.parse(component.subheading?.raw))}
            </p>
          )}
          {callsToAction && <HomeHeroCTA callsToAction={callsToAction} />}
        </div>
        <div className="home-hero--featured-image-container">
          
          <div className="home-hero--anim-wrapper">
            <div className="home-hero--lottie-wrapper">
              <div className="home-hero--anim-overlay" />
              <div className="home-hero--iframe-wrapper">
                {videoData && videoData?.length > 0 && (
                  <ContentfulVideo videoData={videoData[0]?.reference} />
                )}
              </div>
            </div>
          </div>
          <div className="home-hero--video-wrapper">
            {renderMarquee({ speed: 10, direction: "right" })}
            {renderMarquee({ speed: 15 })}
            {renderMarquee({ speed: 12, direction: "right" })}
          </div>
        </div>
      </HomeHeroWrapper>
    </div>
  )
}

MainAnimation.displayName = "MainAnimation"
