import React from "react"
import { HeadingWrapper, Kicker, HeadingText, Subheading, ContentWrapper, CtaWrapper } from "./styles/Heading.styled"
import { Button } from "../../atoms/Button"

const Heading = ({ component }) => {
  const {
    backgroundColor,
    kicker,
    headingSize,
    heading,
    subheading,
    callsToAction,
    noTopPadding,
  } = component

  const headingClass = headingSize.replace(/\s/g, "-").toLowerCase()

  return (
    <HeadingWrapper bgColor={backgroundColor?.toLowerCase()} noTopPadding={noTopPadding}>
      <ContentWrapper>
        {kicker && <Kicker>{kicker}</Kicker>}
        {heading && <HeadingText className={headingClass}>{heading}</HeadingText>}
        {subheading && <Subheading>{subheading?.subheading}</Subheading>}
        {callsToAction &&
          <CtaWrapper>
            {callsToAction?.map((cta) => (
              <Button
                ctaLink={cta?.link}
                ctaVariant={cta?.variant}
                key={cta?.id}
                {...cta}
              >
                {cta?.label}
              </Button>
            ))}
          </CtaWrapper>
        }
      </ContentWrapper>
    </HeadingWrapper>
  )
}

export default Heading